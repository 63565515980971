/** @jsx jsx */
import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { jsx, Styled } from "theme-ui"
import { Layout, Container, Header, Stack, ExternalLink } from "../components"
import SEO from "../components/SEO"

const resourcesProps = {
  1: {
    title: "SF Counts Resources",
    description:
      "Encourage your friends, family, and community to do the Census with these shareable resources.",
    link: "https://bit.ly/sfcountsresources",
  },
  2: {
    title: "sf.gov/census",
    description:
      "San Francisco counts for the 2020 Census. Check out what the City is doing to educate residents.",
    link: "https://sf.gov/census",
  },
  3: {
    title: "2020 Census Language Guides",
    description:
      "U.S. Census Bureau translated web pages and guides in 59 non-English languages.",
    link: "https://2020census.gov/en/languages.html",
  },
  4: {
    title: "SF Counts social media toolkit",
    description:
      "Get your community counted with our SF Counts social media graphics and messages",
    link: "https://bit.ly/sfcountstoolkit2",
  },
  5: {
    title: "SF Counts graphics - English",
    description: "",
    link: "http://bit.ly/sfcountstoolkitenglish",
  },
  6: {
    title: "SF Counts graphics - Spanish",
    description: "",
    link: "http://bit.ly/sfcountstoolkitspanish",
  },
  7: {
    title: "SF Counts graphics - Chinese",
    description: "",
    link: "http://bit.ly/sfcountstoolkitchinese",
  },
  8: {
    title: "SF Counts graphics - Filipino",
    description: "",
    link: "http://bit.ly/sfcountstoolkitfilipino",
  },
  9: {
    title: "SF Counts graphics - Russian",
    description: "",
    link: "https://bit.ly/sfcountstoolkitrussian",
  },
  10: {
    title: "Come to Your Census, SF!",
    description:
      "Come To Your Census arts-based toolkit and shareable resources from our partners Art+Action",
    link: "https://cometoyourcensus.us/toolkit/",
  },
  11: {
    title: "SF Counts x Radici Studios coloring page",
    description: "",
    link: "https://bit.ly/sfcountscoloring1",
  },
  12: {
    title: "Multilingual Email Messages",
    description: "Multilingual newsletter messages.",
    link: "https://bit.ly/sfcountsblurbs2",
  },
}

const PageBanner = ({ bannerSrc }) => (
  <div
    sx={{
      height: [300, 475],
      borderRadius: [0, "big"],
      variant: "flex.space",
      overflow: "hidden",
    }}
  >
    <Styled.h1 sx={{ flex: 1, position: "relative", top: "44%" }}>
      Community Resources
    </Styled.h1>

    <Img
      fluid={bannerSrc}
      style={{
        height: "110%",
        flex: 1,
      }}
      imgStyle={{
        objectFit: "contain",
      }}
    ></Img>
  </div>
)

const ResourcesPage = ({ data: { hero, resourcesImages } }) => (
  <Layout>
    <SEO title="Support our community with these downloadable 2020 Census resources." />
    <div sx={{ paddingY: [40, 72] }}>
      <Stack>
        {console.log("resourcesImages", resourcesImages)}
        <Header />
        <div sx={{ backgroundColor: "#EFEFF0" }}>
          <Container>
            <PageBanner bannerSrc={hero.childImageSharp.fluid}></PageBanner>
          </Container>
        </div>
        <Container>
          <Styled.h3 sx={{ marginBottom: [24, 32] }}>
            Support our community with these downloadable{" "}
            <br sx={{ display: ["none", "block"] }} />
            2020 Census resources.
          </Styled.h3>
          <div
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(210px,1fr))",
              gridGap: 32,
            }}
          >
            {resourcesImages.edges
              // Sort resources by image number
              .sort(({ node: a }, { node: b }) => {
                return parseInt(a.name) - parseInt(b.name)
              })
              .map(({ node }) => {
                const r = resourcesProps[node.name]
                console.log("r", r)

                return (
                  <ExternalLink
                    to={r.link}
                    sx={{
                      ":hover": {
                        boxShadow: "none",
                      },
                    }}
                    key={r.title}
                  >
                    <figure
                      sx={{
                        borderRadius: "small",
                        overflow: "hidden",
                        marginBottom: 12,
                        transition:
                          "150ms all cubic-bezier(0, 0.66, 0.77, 1.08)",
                        ":hover": {
                          boxShadow: "none",
                          transform: "scale(1.07)",
                          opacity: 0.9,
                        },
                      }}
                    >
                      <Img
                        fluid={node.childImageSharp.fluid}
                        imgStyle={{
                          objectFit: "contain",
                        }}
                      ></Img>
                    </figure>
                    <div sx={{ marginLeft: 8 }}>
                      <Styled.h4>{r.title}</Styled.h4>
                      <span sx={{ variant: "text.medium" }}>
                        {r.description}
                      </span>
                    </div>
                  </ExternalLink>
                )
              })}
          </div>
        </Container>
      </Stack>
    </div>
  </Layout>
)

export default ResourcesPage

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "resources-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    resourcesImages: allFile(
      sort: { fields: [name, base], order: ASC }
      filter: {
        extension: { regex: "/(png)/" }
        relativeDirectory: { eq: "resources" }
      }
    ) {
      edges {
        node {
          base
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
